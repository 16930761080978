export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'PieChartIcon',
    resource: 'ADMIN',
    action: 'manage',
  },
  {
    title: 'Ordenes',
    route: 'ordenes',
    icon: 'ClipboardIcon',
    resource: 'ADMIN',
    action: 'manage',
  },
  {
    title: 'Puntos Iluminación',
    route: 'second-page',
    icon: 'ListIcon',
    resource: 'ADMIN',
    action: 'manage',
  },
  {
    title: 'Ubicacion de Puntos',
    route: 'order-location',
    icon: 'MapPinIcon',
    resource: 'ADMIN',
    action: 'manage',
  },
  {
    title: 'Usuarios',
    icon: 'UsersIcon',
    route: 'usuarios',
    resource: 'ADMIN',
    action: 'manage',
  },
  {
    title: 'Administración',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Localización',
        icon: 'NavigationIcon',
        resource: 'ADMIN',
        action: 'manage',
        children: [
          {
            title: 'Departamentos',
            route: 'localizacion-departamentos',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Municipios',
            route: 'localizacion-municipios',
            resource: 'ADMIN',
            action: 'manage',
          },
        ],
      },
      {
        title: 'Catálogos',
        icon: 'ListIcon',
        children: [
          {
            title: 'Regiones',
            route: 'catalogos-regiones',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'SubRegiones',
            route: 'catalogos-subregiones',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Coordinaciones',
            route: 'catalogos-coordinaciones',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Escalas Puestos',
            route: 'catalogos-escalas',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Puestos',
            route: 'catalogos-plazas',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Documentos',
            route: 'catalogos-documentos',
            resource: 'ADMIN',
            action: 'manage',
          },
        ],
      },
    ],
  },
]
